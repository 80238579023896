import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import "../scss/App.scss"

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Terms And Conditions" />
    <section>
      <div className="container page-content-wrapper">
        <h1>Referral Agreement</h1>
        <p>This Referral Agreement (the “Agreement”) is entered into on [the Effective Date], by and between:</p>
        <p>Company name, legal entity code: [X], with an address of [X] (hereinafter referred to as the “Referrer”), represented by the [job title] [name and surname] and MB "Hades Media”, legal entity code: 305239886, with an address of A. Goštauto g. 8-243A, Vilnius, Lithuania (hereinafter referred to as the “Company”),</p>
        <ol>
          <li>Purpose. The Company is in the business of providing digital marketing services. The Company wishes to gain additional clients/customers with ad spends of at least US$20,000 per month for digital marketing services, including but not limited to paid social media advertising, email marketing, Google advertising and conversion rate optimization. The Referrer is in a position to refer potential clients/customers to the Company.</li>
          <li>Referral Arrangement. Upon the Effective Date of this Agreement, the Referrer may, from time to time, refer potential clients/customers to the Company. The Company will pay the Referrer a fee for these referrals.</li>
          <li>Compensation. The Company shall pay the Referrer $4,000 for each successful referral, where a successful referral is defined as a referral when the referee purchases the 6 week trial package from the Company. The Company shall pay the Referrer $0 for each unsuccessful referral, where an unsuccessful referral is defined as a valid referral candidate that does not purchases the 6 week trial package from the Company through no fault of the Referrer or the Company; and a valid referral candidate is a potential client/customer that meets the specifications stated in Section 1 above. The Company shall pay the Referrer within fifty (50) days of a completed referral, where a completed referral will be the day of signing the referee to a 6 week trial period.</li>
          <li>Term. This Agreement shall commence upon the Effective Date, as stated above, and will continue until one of the Parties wishes to terminate the Agreement described in the section 6 of the Agreement .</li>
          <li>Confidentiality. During the course of this Agreement, it may be necessary for the Company to share proprietary information, including trade secrets, industry knowledge, and other confidential information, to the Referrer in order for the Referrer to seek out potential referrals. The Referrer will not share any of this proprietary information at any time. The Referrer also will not use any of this proprietary information for the Referrer’s personal benefit at any time. This section remains in full force and effect even after termination of the Agreement by it’s natural termination or the early termination by either Party.
            <br/>
            a. If the Referrer would breach confidentiality obligations imposed by this Agreement, the Referrer shall pay a fine to the Company of not less than 2’000 EUR for each case of unlawful disclosure or use of confidential information. The Parties agree that the fine specified herein shall be considered as minimal losses of the Company which will not need to be supported by material evidence. The payment of fine does not exempt the Referrer from liability to recover any other factual losses if such losses would exceed the amount of fine.
          </li>
          <li>Termination. This Agreement may be terminated at any time by either Party upon 30 days written notice to the other Party. Upon termination, the Company shall pay the Referrer all compensation due and owing for referrals made prior to the date of termination, but not yet paid.</li>
          <li>Representations and Warranties. Both Parties represent that they are fully authorized to enter into this Agreement. The performance and obligations of either Party will not violate or infringe upon the rights of any third party or violate any other agreement between the Parties, individually, and any other person, organization, or business or any law or governmental regulation.</li>
          <li>Indemnity. The Parties each agree to indemnify and hold harmless the other Party, its respective Referrers, officers, agents, employees, and permitted successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result from the negligence of or breach of this Agreement by the indemnifying Party, or its respective successors and assigns that occurs in connection with this Agreement. This section remains in full force and effect even after termination of the Agreement by its natural termination or the early termination by either Party.</li>
          <li>Limitation of Liability. UNDER NO CIRCUMSTANCES SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY DAMAGES RESULTING FROM ANY PART OF THIS AGREEMENT SUCH AS, BUT NOT LIMITED TO, LOSS OF REVENUE OR ANTICIPATED PROFIT OR LOST BUSINESS, COSTS OF DELAY OR FAILURE OF DELIVERY, WHICH ARE NOT RELATED TO OR THE DIRECT RESULT OF A PARTY’S NEGLIGENCE OR BREACH.</li>
          <li>Disclaimer of Warranties. The Referrer shall refer potential clients/customers as requested by the Company. THE Referrer DOES NOT REPRESENT OR WARRANT THAT SUCH REFERRALS WILL CREATE ANY ADDITIONAL PROFITS, SALES, EXPOSURE, BRAND RECOGNITION, OR THE LIKE. THE Referrer HAS NO RESPONSIBILITY TO THE COMPANY IF THE REFERRALS DO NOT LEAD TO THE COMPANY’S DESIRED RESULT(S).</li>
          <li>Severability. In the event any provision of this Agreement is deemed invalid or unenforceable, in whole or in part, that part shall be severed from the remainder of the Agreement and all other provisions should continue in full force and effect as valid and enforceable.</li>
          <li>Waiver. The failure by either Party to exercise any right, power, or privilege under the terms of this Agreement will not be construed as a waiver of any subsequent or future exercise of that right, power, or privilege or the exercise of any other right, power, or privilege.</li>
          <li>Legal Fees. In the event of a dispute resulting in legal action, the successful Party will be entitled to its legal fees, including, but not limited to its attorneys’ fees.</li>
          <li>Legal and Binding Agreement. This Agreement is legal and binding between the Parties as stated above. This Agreement may be entered into and is legal and binding both in the United States and throughout Europe. The Parties each represent that they have the authority to enter into this Agreement.</li>
          <li>Governing Law and Jurisdiction. The Parties agree that this Agreement shall be governed by the laws of the Republic of Lithuania. The rights and obligations arising from this Agreement shall be carried out and explained by laws of the Republic of Lithuania.</li>
          <li>Entire Agreement. The Parties acknowledge and agree that this Agreement represents the entire Agreement between the Parties. In the event that the Parties desire to change, add, or otherwise modify any terms, they shall do so in writing to be signed by both Parties.</li>
        </ol>
      </div>
    </section>
  </Layout>
)

export default TermsAndConditions
